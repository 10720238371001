import React from 'react';

const NumberInput = ({ inputValue, onChange, onKeyPress, label }) => {
    return (
        <div className="filter-item">
            <label htmlFor={label} >{label}:</label>
            <div>
                <input
                    className="number-input"
                    type="number"
                    id={`${label.toLowerCase()}-number-input`}
                    value={inputValue}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    min={0}
                    step={1}
                />
            </div>
        </div>
    );
};

export default NumberInput;
