import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './MessageLogs.css';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SanitizedTableCell from './TableCellComponents/SanitizedTableCell';
import DropdownTableHeader from './TableCellComponents/DropdownTableHeader';
import StatusTableCell from './TableCellComponents/StatusTableCell';
import FileTableCell from './TableCellComponents/FileTableCell';
import FilterBar from './FilterComponents/FilterBar';
import DialogOkCancelFormComponent from './Modal/DialogOkCancelFormComponent';
import { resendFile } from '../helpers/fileHelper';
import { getApiKey } from '../helpers/stringHelpers';
import { getLocaleDateTime, getYesterday, getTomorrow } from '../helpers/dateTimeHelper';

function MessageLogs({ config }) {
    const inital_maxRows = 100;
    const dropdownDaysAgo = 20;

    const { accounts, instance } = useMsal();

    //Init states
    //const [dropdownDaysAgo, setdropdownDaysAgo] = useState(20);

    const [logMessageTable, setLogMessageTable] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const [filterWords, setFilterWords] = useState('');
    const [dateFrom, setDateFrom] = useState(getYesterday());
    const [dateTo, setDateTo] = useState(getTomorrow());
    const [maxRows, setMaxRows] = useState(inital_maxRows);
    const [maxRowsInputValue, setMaxRowsInputValue] = useState(inital_maxRows);

    const [selectedSender, setSelectedSender] = useState('All');
    const [selectedReceiver, setSelectedReceiver] = useState('All');
    const [selectedTransactionType, setSelectedTransactionType] = useState('All');
    const [selectedMessageType, setSelectedMessageType] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');

    const [dropdownTable, setDropdownTable] = useState([]);

    const [senderDropdown, setSenderDropdown] = useState([]);
    const [receiverDropdown, setReceiverDropdown] = useState([]);
    const [transactionTypeDropdown, setTransactionTypeDropdown] = useState([]);
    const [messageTypeDropdown, setMessageTypeDropdown] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState([]);

    const [loading, setLoading] = useState(false); // Loading log message table
    const [loadingDropdownOptions, setLoadingDropdownOptions] = useState(false); // Loading dropdown options

    const [inputEnterKey, setInputEnterKey] = useState(0); // Unique key for each enter input event
    const [maxRowsInputEnterKey, setMaxRowsInputEnterKey] = useState(0); // Unique key for each enter input event

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState("");

    //Confirmation Modal
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleModalClose = () => {
        closeModal();
        setModalData("");
    };

    const handleModalOK = () => {
        resendFile(modalData, config, accounts, instance);
        closeModal();
    };

    //Resend file callback
    const handleResendFile = (modalData) => {
        setModalData(modalData);
        openModal();
    };

    // Handle filter changes
    const handleInputChange = (e) => {
        // Update the inputValue state when the input value changes
        setInputValue(e.target.value);
        setMaxRows(maxRowsInputValue);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            setFilterWords(e.target.value);
            setInputEnterKey(prevKey => prevKey + 1); // Update key to trigger useEffect
        }
    };

    const handleSearchClick = () => {
        setFilterWords(inputValue);
        setMaxRows(maxRowsInputValue);
    };

    // Date Picker changes
    const handleDateFromChange = (selectedDate) => {
        setDateFrom(selectedDate);
    };

    const handleDateToChange = (selectedDate) => {
        setDateTo(selectedDate);
    };

    // Handle filter changes
    const handleMaxRowsChange = (e) => {
        // Update the inputValue state when the input value changes
        setMaxRowsInputValue(e.target.value);
    };

    const handleMaxRowsKeyPress = (e) => {
        if (e.key === 'Enter') {
            setMaxRows(e.target.value);
            setMaxRowsInputEnterKey(prevKey => prevKey + 1); // Update key to trigger useEffect
        }
    };

    const toggleDatePicker = (id) => {
        const datePicker = document.getElementById(id);
        if (datePicker) {
            datePicker.focus();
        }
    };

    //const copyRowToClipboard = (row) => {
    //    // Create an HTML string for the row with enhanced styles for better readability
    //    const rowHtml = `
    //  <div style="overflow-x: auto;">
    //    <table border="1" style="border-collapse: collapse; width: max-content; font-size: 16px;">
    //      <tr>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.processed}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.sender}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.receiver}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.transactionType}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.messageType}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.transactionTag}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.description}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.status}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.scope}</td>
    //        <td style="border: 1px solid black; padding: 8px; white-space: nowrap;">${row.system}</td>
    //      </tr>
    //    </table>
    //  </div>
    //`;

    //    const blob = new Blob([rowHtml], { type: 'text/html' });
    //    const clipboardItem = new ClipboardItem({ 'text/html': blob });

    //    navigator.clipboard.write([clipboardItem])
    //        .then(() => {
    //            console.log('Row copied to clipboard as HTML');
    //        })
    //        .catch((err) => {
    //            console.error('Failed to copy row: ', err);
    //        });
    //};


    // Dropdown value changes
    const handleDropdownChange = (e, dropdownType) => {
        const selectedValue = e.target.value;

        switch (dropdownType) {
            case 'Sender':
                setSelectedSender(selectedValue);
                updateDropdowns(selectedValue, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus, 'Sender');
                break;
            case 'Receiver':
                setSelectedReceiver(selectedValue);
                updateDropdowns(selectedSender, selectedValue, selectedTransactionType, selectedMessageType, selectedStatus, 'Receiver');
                break;
            case 'TransactionType':
                setSelectedTransactionType(selectedValue);
                updateDropdowns(selectedSender, selectedReceiver, selectedValue, selectedMessageType, selectedStatus, 'TransactionType');
                break;
            case 'MessageType':
                setSelectedMessageType(selectedValue);
                updateDropdowns(selectedSender, selectedReceiver, selectedTransactionType, selectedValue, selectedStatus, 'MessageType');
                break;
            case 'Status':
                setSelectedStatus(selectedValue);
                updateDropdowns(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedValue, 'Status');
                break;
            default:
                break;
        }
    };

    // This useEffect finds the inital value for the dropdown. It should be all element from log messages for the last month
    useEffect(() => {
        setLoadingDropdownOptions(true);
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                // Get config values

                const logmsgApiUrl = config.logMsgApiUrl;
                const apiKey = getApiKey(config.enviromentScope);

                // Setup GET query
                let getRequest = logmsgApiUrl + '/LogsGetGroup?days=' + dropdownDaysAgo + '&type=7';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': apiKey
                };

                // Make the API request
                axios.get(getRequest, {
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        var uniqSenders = response.data.map(item => item.sender).filter((value, index, self) => self.indexOf(value) === index);
                        var uniqReceivers = response.data.map(item => item.receiver).filter((value, index, self) => self.indexOf(value) === index);
                        var uniqTransactionTypes = response.data.map(item => item.transactionType).filter((value, index, self) => self.indexOf(value) === index);
                        var uniqMessageTypes = response.data.map(item => item.messageType).filter((value, index, self) => self.indexOf(value) === index);
                        var uniqStatuses = response.data.map(item => item.status).filter((value, index, self) => self.indexOf(value) === index);

                        setDropdownTable(response.data);
                        setSenderDropdown(uniqSenders.sort());
                        setReceiverDropdown(uniqReceivers.sort());
                        setTransactionTypeDropdown(uniqTransactionTypes.sort());
                        setMessageTypeDropdown(uniqMessageTypes.sort());
                        setStatusDropdown(uniqStatuses);
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    })
                    .finally(() => {
                        setLoadingDropdownOptions(false);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []); // Empty dependency array to run the effect only once when the component is mounted

    // Find all log messages from API, based on filters: word, dates and dropdown.
    // If any of these filter changes this useEffect should run
    useEffect(() => {
        setLoading(true); // Set loading to true when starting to fetch data

        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                // Get config values

                const logmsgApiUrl = config.logMsgApiUrl;
                const apiKey = getApiKey(config.enviromentScope);

                // Setup GET query
                let getRequest = logmsgApiUrl + '/LogsGetAllNoBody?';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': apiKey
                };

                // Update Params based on state of the filters
                const updateQueryParams = () => {
                    const APIKEY = apiKey;
                    const dtFrom = dateFrom.toISOString().split('T')[0];
                    const dtTo = dateTo.toISOString().split('T')[0];
                    const filter = filterWords;
                    const sender = selectedSender !== "All" ? selectedSender : null;
                    const receiver = selectedReceiver !== "All" ? selectedReceiver : null;
                    const transactionType = selectedTransactionType !== "All" ? selectedTransactionType : null;
                    const messageType = selectedMessageType !== "All" ? selectedMessageType : null;
                    const status = selectedStatus !== "All" ? selectedStatus : null;
                    return { APIKEY, dtFrom, dtTo, maxRows: maxRows, filter, sender, receiver, transactionType, messageType, status: status };
                };
                const queryParams = updateQueryParams();

                // Make the API request
                axios.get(getRequest, {
                    params: queryParams,
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        if (response.data === "" || response.data === null) {
                            setLogMessageTable([]);
                        } else {
                            setLogMessageTable(response.data);
                        }
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    })
                    .finally(() => {
                        setLoading(false); // Set loading to false when data fetching is completed (success or failure)
                    });
            })
            .catch((error) => {
                //if (error instanceof InteractionRequiredAuthError) {
                //    instance
                //        .acquireTokenPopup(accessTokenRequest)
                //        .then(function (accessTokenResponse) {
                //            // Acquire token interactive success
                //            let accessToken = accessTokenResponse.accessToken;
                //            // Call your API with token
                //            callApi(accessToken).then((response) => {
                //                setApiData(response);
                //            });
                //        })
                //        .catch(function (error) {
                //            // Acquire token interactive failure
                //            console.log(error);
                //        });
                //}
                console.log(error);
            });
    }, [dateFrom, dateTo, filterWords, selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus, inputEnterKey, maxRows, maxRowsInputEnterKey]); // Use dependency array to run the effect only when at least one of these values are changed

    function updateDropdowns(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus, dropdown) {
        //Update all other dropdown states in each if
        if (dropdown === 'Sender') {
            var dropdownReceivers = getReceiverDropdown(selectedSender, selectedTransactionType, selectedMessageType, selectedStatus);
            setReceiverDropdown(dropdownReceivers);

            var dropdownTransactionType = getTransactionTypeDropdown(selectedSender, selectedReceiver, selectedMessageType, selectedStatus);
            setTransactionTypeDropdown(dropdownTransactionType);

            var dropdownMessageType = getMessageTypeDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedStatus);
            setMessageTypeDropdown(dropdownMessageType);

            var dropdownStatus = getStatusDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setStatusDropdown(dropdownStatus);
        } else if (dropdown === 'Receiver') {
            var dropdownSenders = getSenderDropdown(selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setSenderDropdown(dropdownSenders);

            var dropdownTransactionType = getTransactionTypeDropdown(selectedSender, selectedReceiver, selectedMessageType, selectedStatus);
            setTransactionTypeDropdown(dropdownTransactionType);

            var dropdownMessageType = getMessageTypeDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedStatus);
            setMessageTypeDropdown(dropdownMessageType);

            var dropdownStatus = getStatusDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setStatusDropdown(dropdownStatus);
        } else if (dropdown === 'TransactionType') {
            var dropdownSenders = getSenderDropdown(selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setSenderDropdown(dropdownSenders);

            var dropdownReceivers = getReceiverDropdown(selectedSender, selectedTransactionType, selectedMessageType, selectedStatus);
            setReceiverDropdown(dropdownReceivers);

            var dropdownMessageType = getMessageTypeDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedStatus);
            setMessageTypeDropdown(dropdownMessageType);

            var dropdownStatus = getStatusDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setStatusDropdown(dropdownStatus);
        } else if (dropdown === "MessageType") {
            var dropdownSenders = getSenderDropdown(selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setSenderDropdown(dropdownSenders);

            var dropdownReceivers = getReceiverDropdown(selectedSender, selectedTransactionType, selectedMessageType, selectedStatus);
            setReceiverDropdown(dropdownReceivers);

            var dropdownTransactionType = getTransactionTypeDropdown(selectedSender, selectedReceiver, selectedMessageType, selectedStatus);
            setTransactionTypeDropdown(dropdownTransactionType);

            var dropdownStatus = getStatusDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedMessageType);
            setStatusDropdown(dropdownStatus);
        } else if (dropdown === "Status") {
            var dropdownSenders = getSenderDropdown(selectedReceiver, selectedTransactionType, selectedMessageType, selectedStatus);
            setSenderDropdown(dropdownSenders);

            var dropdownReceivers = getReceiverDropdown(selectedSender, selectedTransactionType, selectedMessageType, selectedStatus);
            setReceiverDropdown(dropdownReceivers);

            var dropdownTransactionType = getTransactionTypeDropdown(selectedSender, selectedReceiver, selectedMessageType, selectedStatus);
            setTransactionTypeDropdown(dropdownTransactionType);

            var dropdownMessageType = getMessageTypeDropdown(selectedSender, selectedReceiver, selectedTransactionType, selectedStatus);
            setMessageTypeDropdown(dropdownMessageType);
        }
    }

    /*
     * Get all senders in senderdropdown based on selected values in all other dropdowns
     */
    function getSenderDropdown(currentSelectedReceiver, currentSelectedTransactionType, currentSelectedMessageType, currentSelectedStatus) {
        var newDropdownValues = dropdownTable;

        if (currentSelectedReceiver !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.receiver === currentSelectedReceiver;
            });
        }

        if (currentSelectedTransactionType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.transactionType === currentSelectedTransactionType;
            });
        }

        if (currentSelectedMessageType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.messageType === currentSelectedMessageType;
            });
        }

        if (currentSelectedStatus !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.status === currentSelectedStatus;
            });
        }

        var uniqSenders = newDropdownValues.map(item => item.sender).filter((value, index, self) => self.indexOf(value) === index);
        return uniqSenders.sort();
    }

    function getReceiverDropdown(currentSelectedSender, currentSelectedTransactionType, currentSelectedMessageType, currentSelectedStatus) {
        var newDropdownValues = dropdownTable;

        if (currentSelectedSender !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.sender === currentSelectedSender;
            });
        }

        if (currentSelectedTransactionType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.transactionType === currentSelectedTransactionType;
            });
        }

        if (currentSelectedMessageType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.messageType === currentSelectedMessageType;
            });
        }

        if (currentSelectedStatus !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.status === currentSelectedStatus;
            });
        }

        var uniqReceivers = newDropdownValues.map(item => item.receiver).filter((value, index, self) => self.indexOf(value) === index);
        return uniqReceivers.sort();
    }

    function getTransactionTypeDropdown(currentSelectedSender, currentSelectedReceiver, currentSelectedMessageType, currentSelectedStatus) {
        var newDropdownValues = dropdownTable;

        if (currentSelectedSender !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.sender === currentSelectedSender;
            });
        }

        if (currentSelectedReceiver !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.receiver === currentSelectedReceiver;
            });
        }

        if (currentSelectedMessageType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.messageType === currentSelectedMessageType;
            });
        }

        if (currentSelectedStatus !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.status === currentSelectedStatus;
            });
        }

        var uniqTransactionTypes = newDropdownValues.map(item => item.transactionType).filter((value, index, self) => self.indexOf(value) === index);
        return uniqTransactionTypes.sort();
    }

    function getMessageTypeDropdown(currentSelectedSender, currentSelectedReceiver, currentSelectedTransactionType, currentSelectedStatus) {
        var newDropdownValues = dropdownTable;

        if (currentSelectedSender !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.sender === currentSelectedSender;
            });
        }

        if (currentSelectedReceiver !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.receiver === currentSelectedReceiver;
            });
        }

        if (currentSelectedTransactionType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.transactionType === currentSelectedTransactionType;
            });
        }

        if (currentSelectedStatus !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.status === currentSelectedStatus;
            });
        }

        var uniqMessageTypes = newDropdownValues.map(item => item.messageType).filter((value, index, self) => self.indexOf(value) === index);
        return uniqMessageTypes.sort();
    }

    function getStatusDropdown(currentSelectedSender, currentSelectedReceiver, currentSelectedTransactionType, currentSelectedMessageType) {
        var newDropdownValues = dropdownTable;

        if (currentSelectedSender !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.sender === currentSelectedSender;
            });
        }

        if (currentSelectedReceiver !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.receiver === currentSelectedReceiver;
            });
        }

        if (currentSelectedTransactionType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.transactionType === currentSelectedTransactionType;
            });
        }

        if (currentSelectedMessageType !== "All") {
            newDropdownValues = newDropdownValues.filter(function (item) {
                return item.messageType === currentSelectedMessageType;
            });
        }

        var uniqStatus = newDropdownValues.map(item => item.status).filter((value, index, self) => self.indexOf(value) === index);
        return uniqStatus.sort();
    }

    return (
        <div className="message-logs-container">
            <h2 className="message-logs-title">Message Logs</h2>

            {/* Filter inputs in a horizontal line */}
            <FilterBar
                config={config}
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleInputKeyPress={handleInputKeyPress}
                handleSearchClick={handleSearchClick}
                dateFrom={dateFrom}
                handleDateFromChange={handleDateFromChange}
                toggleDatePicker={toggleDatePicker}
                dateTo={dateTo}
                handleDateToChange={handleDateToChange}
                maxRowsInputValue={maxRowsInputValue}
                handleMaxRowsChange={handleMaxRowsChange}
                handleMaxRowsKeyPress={handleMaxRowsKeyPress}
            />

            {loading ? (
                <div className="loading-container">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" size="3x" spin />
                    <p className="loading-text">Loading...</p>
                </div>
            ) : (
                <div className="table-container">
                    <table className="message-logs-table">
                        <thead>
                            <tr>
                                {/*<th style={{ maxWidth: "60px" }}>Copy That</th>*/}
                                <th style={{ maxWidth: "67px" }}>Processed</th>
                                <DropdownTableHeader
                                    title="Sender:"
                                    style={{ maxWidth: "80px" }}
                                    loadingDropdownOptions={loadingDropdownOptions}
                                    selectedValue={selectedSender}
                                    options={senderDropdown}
                                    handleDropdownChange={(e) => handleDropdownChange(e, 'Sender')}
                                />
                                <DropdownTableHeader
                                    title="Receiver:"
                                    style={{ maxWidth: "80px" }}
                                    loadingDropdownOptions={loadingDropdownOptions}
                                    selectedValue={selectedReceiver}
                                    options={receiverDropdown}
                                    handleDropdownChange={(e) => handleDropdownChange(e, 'Receiver')}
                                />
                                <DropdownTableHeader
                                    title="Transaction Type:"
                                    style={{ maxWidth: "80px" }}
                                    loadingDropdownOptions={loadingDropdownOptions}
                                    selectedValue={selectedTransactionType}
                                    options={transactionTypeDropdown}
                                    handleDropdownChange={(e) => handleDropdownChange(e, 'TransactionType')}
                                />
                                <DropdownTableHeader
                                    title="Message Type:"
                                    style={{ maxWidth: "80px" }}
                                    loadingDropdownOptions={loadingDropdownOptions}
                                    selectedValue={selectedMessageType}
                                    options={messageTypeDropdown}
                                    handleDropdownChange={(e) => handleDropdownChange(e, 'MessageType')}
                                />
                                <th style={{ maxWidth: "70px" }}>Transaction Tag</th>
                                <th style={{ maxWidth: "400px" }}>Description</th>
                                <DropdownTableHeader
                                    title="Status:"
                                    style={{ maxWidth: "50px" }}
                                    loadingDropdownOptions={loadingDropdownOptions}
                                    selectedValue={selectedStatus}
                                    options={statusDropdown}
                                    handleDropdownChange={(e) => handleDropdownChange(e, 'Status')}
                                />
                                <th style={{ maxWidth: "70px" }}>FileName</th>
                                <th style={{ maxWidth: "40px" }}>Scope</th>
                                <th style={{ maxWidth: "40px" }}>System</th>
                            </tr>
                        </thead>

                        <tbody>
                            {logMessageTable.map((row, index) => (
                                <tr key={index}>
                                    {/*<td>*/}
                                    {/*    <button onClick={() => copyRowToClipboard(row)}>Copy Entire Row</button>*/}
                                    {/*</td>*/}
                                    <td style={{ maxWidth: "67px" }}>{getLocaleDateTime(row.processed)}</td>
                                    <td style={{ maxWidth: "80px" }}>{row.sender}</td>
                                    <td style={{ maxWidth: "80px" }}>{row.receiver}</td>
                                    <td style={{ maxWidth: "80px" }}>{row.transactionType}</td>
                                    <td style={{ maxWidth: "80px" }}>{row.messageType}</td>
                                    <td style={{ maxWidth: "70px" }}>{row.transactionTag}</td>
                                    <SanitizedTableCell stringValue={row.description} style={{ maxWidth: "400px" }} />
                                    <StatusTableCell status={row.status} />
                                    <FileTableCell row={row}
                                        config={config}
                                        modalOpen={modalOpen}
                                        openModal={openModal}
                                        closeModal={closeModal}
                                        onResendFile={handleResendFile}
                                        accounts={accounts}
                                        instance={instance}
                                        style={{ maxWidth: "70px" }}
                                    />
                                    <td style={{ maxWidth: "40px" }}>{row.scope}</td>
                                    <td style={{ maxWidth: "40px" }}>{row.system}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <DialogOkCancelFormComponent
                isOpen={modalOpen}
                onRequestClose={handleModalClose}
                TextTitle={modalData.TextTitle}
                TextBody1={modalData.TextBody1}
                TextBody2={modalData.TextBody2}
                TextBody3={modalData.TextBody3}
                TextBody4={modalData.TextBody4}
                TextBody5={modalData.TextBody5}
                onResult={handleModalOK}
                onClose={handleModalClose}
            />
        </div>
    );
}

export default MessageLogs;
