import React from 'react';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';
import NumberInput from './NumberInput';

const FilterBar = ({
    config,
    inputValue,
    handleInputChange,
    handleInputKeyPress,
    handleSearchClick,
    dateFrom,
    handleDateFromChange,
    toggleDatePicker,
    dateTo,
    handleDateToChange,
    maxRowsInputValue,
    handleMaxRowsChange,
    handleMaxRowsKeyPress}) => {
    return (
        <div className="filter-container" style={{ backgroundColor: config.color }}>
            <TextFilter
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleInputKeyPress={handleInputKeyPress}
                handleSearchClick={handleSearchClick}
            />
            <DateFilter
                selectedDate={dateFrom}
                handleDateChange={handleDateFromChange}
                toggleDatePicker={toggleDatePicker}
                label="From Date"
            />
            <DateFilter
                selectedDate={dateTo}
                handleDateChange={handleDateToChange}
                toggleDatePicker={toggleDatePicker}
                label="To Date"
            />
            <NumberInput
                inputValue={maxRowsInputValue}
                onChange={handleMaxRowsChange}
                onKeyPress={handleMaxRowsKeyPress}
                label="Max Rows"
            />
        </div>
    );
};

export default FilterBar;